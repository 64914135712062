import { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
// import "./Home.scss" 

function App() {
  
      return (
          
              <Router>
                  <Suspense fallback={<div>Loading...</div>}>
                      <Routes>
                          <Route path="/" element={<Home />} />
                      </Routes>
                  </Suspense>
              </Router>
          
      );
}

export default App;