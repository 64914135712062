
import React, { useState } from "react";
import { isDesktop, isIPad13, isMacOs, isIOS, isIPhone13 } from 'react-device-detect'
import { styled } from '@mui/system';
import Box from '@mui/material/Box'; // Import the Box component
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import axios from "axios";

import { animated, useSpring } from '@react-spring/web'
import "../Home.scss";

function Copyright() {
    return (
      <Typography variant="body2" color="#000000" component="footer"
        style={{
            fontSize: isDesktop?'12pt':'8pt',
            fontFamily: 'Times New Roman, Times, serif',
            textAlign: 'center',
        }}
      >
        {' BATMAN and all related characters and elements © & TM DC Comics. (s24) | Un proyecto de '}
        <Link color="inherit" href="https://thisisnicelikethat.com/" target="_blank">
          Nice&nbsp;Like&nbsp;That
        </Link>
        
      </Typography>
    );
  }

const Home = () => {
    const [state, toggle] = useState(true)
    const [open, setOpen] = useState(false);
    const [modalText, setModalText] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [formData, setFormData] = useState({
        optin_fullname: '',
        optin_email: '',
    });
  
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#edead5',
        border: '2px solid #b92025',
        boxShadow: 24,
        borderTopLeftRadius: '255px 15px',
        borderTopRightRadius: '15px 225px',
        borderBottomRightRadius: '255px 15px',
        borderBottomLeftRadius: '15px 225px',
        p: 4,
      };

    const { x } = useSpring({
        from: { x: 0 },
        x: state ? 1 : 0,
        config: { duration: 1000 },
      })
    const peanutsTheme = createTheme({
        palette: {
            primary: {
                main: '#b92025',
            },
            secondary: {
                main: '#ece6d8',
                light: '#ffffff',
                dark: '#b3b3b3',
            },
        },
    });

    const textLogoSpring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 40,
        config: {
            duration: 1500
        }
    });

    const HomeContainer = styled(Container)({
        width: '100%',
        height: '98vh',
    });


    const CustomInput = styled('input')({
        color: '#741417',
        border: '1px solid #ced4da',
        borderRadius: '999px', // un valor alto para hacer que las esquinas sean completamente redondas
        padding: '8px',
        width: '100%',
        boxSizing: 'border-box',
        fontSize: '12pt',
        paddingLeft: '16px',
        marginBottom: isDesktop?'0':'10px',
        '&:focus': {
          outline: 'none',
          borderColor: '#b92025', // ajusta según tu preferencia
          boxShadow: '0.5px 0.5px 0.2px 0.2px #b92025', // ajusta según tu preferencia
        },
      });
      
    const CustomButton = styled('button')({
        border: 'none',
        borderRadius: '999px', // un valor alto para hacer que las esquinas sean completamente redondas
        padding: '10px 50px',
        backgroundColor: '#000000', // ajusta según tu preferencia
        color: '#fff', // ajusta según tu preferencia
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#741417', // ajusta según tu preferencia
        },
        boxShadow: '1px 1px 8px #b92025', // ajusta según tu preferencia
      });

    const CustomH5 = styled('h5')({
        color: '#FFFFFF',
        fontSize: isDesktop?'28pt':"19pt",
        fontWeight: '100',
        fontFamily: 'Times New Roman, Times, serif',
        display: 'block',
        margin: isDesktop?'12px 4px 24px 4px':'8px 4px 12px 4px',
        letterSpacing: '0.40em',
        width: '100%',
    });

    const showModal = (content:string) => {
        setModalText(content);
        handleOpen();
      };

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    // };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        // const { optin_fullname, optin_email } = formData;
        const optin_fullname = (document.getElementById('frm_name') as HTMLInputElement).value;
        const optin_email = (document.getElementById('frm_email') as HTMLInputElement).value;
        try{
            const body = {
                email_address: optin_email,
                status: 'subscribed',
                merge_fields: {
                    FNAME: optin_fullname,
                },
            }
            const resp = await axios.post(
                './mailchimp/index.php', 
                body,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
                );
                if (resp.data.success) {
                    showModal('JOINED');
                  } else {
                    if(resp.data.message === 'user-already-exists'){
                        showModal('REGISTRADO PREVIAMENTE');
                    }else{
                        showModal('Datos incorrectos.');
                    }
                  }
            // console.log(resp)
        } catch (err) {
            console.log("- Error: ",err)
            showModal('Datos incorrectos.');
        }
        // handleOpen();
        // console.log("Form submited");
        // TODO: Implement axios post
    };

    return (
        <ThemeProvider theme={peanutsTheme}>
            <HomeContainer
                style={{
                    maxWidth:isDesktop ? "100%" : "lg",
                    backgroundImage: isDesktop ? "url('./assets/images/Hahahas-Circus-Web-Horz.png')" : "url('./assets/images/Hahahas-Circus-Web-LongVert.png')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.9,
                }}
                
                className="home-container"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: isDesktop?'100px':'33px',
                            flexFlow: 'column',
                        }}

                    >
                        <Box 
                            sx={{ 
                                display: 'flex',
                                justifyContent: 'center', 
                                width: isDesktop ? "1100px" : "98vw",
                            }}

                        >
                            <img
                                src="./assets/images/LogoGCM.png"
                                alt="Gotham City Midnight"
                                className=""
                                width={isDesktop ? '300px' : '40vw'}
                                style={{
                                    width:isDesktop ? '300px' : '40vw'
                                }}
                            />   
                        </Box>
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                width: isDesktop ? "1100px" : "98vw",
                            }}

                        >
                            <animated.div
                                style={{
                                    ...textLogoSpring,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <img
                                    src="./assets/images/Logo-Web-circuss.png"
                                    alt="Gotham City Circus"
                                    className="peanuts-logo"
                                    width={isDesktop ? '1100px' : '98vw'}
                                    style={{
                                        width:isDesktop ? '1100px' : '98vw'
                                    }}
                                />
                            </animated.div>
                        </Box>
                        <Box
                                sx={{
                                    display: isDesktop ? 'none' : 'flex',
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: isDesktop ? "0%" : "420px",
                                    border: '0px solid #b92025',
                                }}
                            >
                                <animated.div

                                >
                                    <img
                                        src="./assets/images/Foot-Web-circuss.png"
                                    />
                                </animated.div>
                            </Box>
                    </Grid>
                    <Grid item xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}

                    >
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                textAlign: 'center',
                                flexDirection: 'column',
                                }}
                        >
                            

                            <p
                                    style={{
                                        
                                    }}
                            >
                                <span
                                    style={{
                                        fontSize: isDesktop ? '19pt' : '16pt',
                                        textShadow: '1px 1px 3px rgba(0,0,0,0.7)',
                                        fontFamily: '-moz-initial Times New Roman, Times, serif',
                                        color: '#715742'
                                    }}
                                >
                                    Suscríbete a nuestra lista de correos y ¡Entérate antes que nadie de la gira del circo por México!
                                </span>
                            </p>

                        </Box>
                    </Grid>
                    <Grid item xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}

                    >
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                width: isDesktop ? "1100px" : "430",
                                }}
                        >
                            <form 
                                onSubmit={handleSubmit}
                                style={{
                                    width: '95%',
                                }}
                            >
                                <FormControl sx={{ mr: 4, width: isDesktop?"35%":'100%' }}>
                                    <CustomInput
                                        type="text"
                                        id="frm_name"
                                        name="frm_name"
                                        placeholder="Nombre"
                                        className="form-control"
                                        required
                                    />
                                </FormControl>
                                <FormControl sx={{ mr: 4, width: isDesktop?"35%":'100%' }}>
                                    <CustomInput
                                        type="email"
                                        id="frm_email"
                                        name="frm_email"
                                        placeholder="Correo"
                                        className="form-control"
                                        required
                                    />
                                </FormControl>
                                <FormControl sx={{ mr: 1, width: isDesktop?"22%":'100%', textAlign: 'right' }}>
                                    <animated.div
                                        // className={styles.text}
                                        style={{
                                        // opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
                                        scale: x.to({
                                            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                                            output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                                        }),
                                        textAlign: 'right',
                                        marginLeft: isDesktop?'0':'auto',
                                        marginRight: isDesktop?'0':'auto',
                                        }}>
                                            <CustomButton 
                                                type="submit" 
                                                color="primary" 
                                                // onClick={() => {
                                                //     toggle(!state);
                                                //     console.log(state);
                                                // }}
                                            >
                                                ¡ÚNETE!
                                            </CustomButton>
                                    </animated.div>
                                </FormControl>
                            </form>
                        </Box>
                    </Grid>
                    <Grid item xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}

                    >
                            <Box
                                sx={{
                                    display: isDesktop ? 'flex' : 'none',
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: isDesktop ? "100%" : "430",
                                }}
                            >
                                <animated.div

                                >
                                    <img
                                        src="./assets/images/Foot-Web-circuss.png"
                                    />
                                </animated.div>
                            </Box>
                    </Grid>
                </Grid>
            </HomeContainer>
            <Container maxWidth="xl">
                <Copyright />    
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalText}
                    </Typography>
                </Box>
            </Modal>
        </ThemeProvider>
    )
};

export default Home;

